@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: 'syne';
  src: url(./../fonts/Syne/Syne-VariableFont_wght.ttf);
}

@font-face {
  font-family: 'inter';
  src: url(./../fonts/Inter/Inter-VariableFont_opsz\,wght.ttf);
}

html{
  height  : 100%;
    overflow: hidden;
    position: relative;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'inter';
  background-color: #1F1F23;
  height  : 100%;
  overflow: auto;
  position: relative;
}

html, body {
  touch-action: manipulation;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'syne';
}

.font-primary {
  font-family: 'syne';
}

.font-secondary {
  font-family: 'inter';
}

.spinner {
  position: absolute;
  top: 48%;
  left: 48%;
  pointer-events: none;
  width: 3em;
  height: 3em;
  border: 0.4em solid #eee;
  border-top-color: #9b4dca;
  border-radius: 50%;
  -webkit-animation: loadingspin 1s linear infinite;
  animation: loadingspin 1s linear infinite;
}

@-webkit-keyframes loadingspin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loadingspin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.pattern-bg {
  background-image: url("../images/pattern-bg.svg");
  background-size: cover;
}

.share-modal-bg {
  background-image: url("../images/share-modal-background.svg");
  background-size: cover;
}

.prevent-scroll {
  overflow: hidden;
}

h1 {
  @apply text-h1;
}

h2 {
  @apply text-h2;
}

h3 {
  @apply text-h3;
}

@keyframes scaleIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.scale-in {
  animation: scaleIn 0.5s ease-out;
}


@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.animate-marquee {
  display: flex;
  animation: marquee 20s linear infinite;
}

.marquee-content {
  display: flex;
  min-width: 100%;
}

@keyframes vertical-flip {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

.coin {
  width: 42px;
  height: 42px;
  background-image: url('../images/StashCoin.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform-origin: center;
}

.flipping {
  animation: vertical-flip 0.8s forwards; /* Shorten duration and add forwards to persist the final state */
}

@keyframes spin {
  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(360deg)
  }
}

.bubble-game-background {
  width: 140%;
  animation: spin 20s linear infinite;
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
  min-height: 22px;
  min-width: 22px;
  max-height: 22px;
  max-width: 22px;
  border-radius: 5px !important;
  border: 2px solid #90A8C1;
  border-radius: 0.5rem;
}

input[type="checkbox"]:checked {
  background-color: #90A8C1;
}

input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('./../images/checkboxCheck.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px;
}

.colink-modal-button button.bg-grey-bg {
  background-color: #444E5C;
  color: #ffffff38;
}

button[disabled] {
  background-color: #444E5C;
  color: #ffffff38;
}

.backdrop-filter-blur {
  backdrop-filter: blur(15px);
}

.inline-block {
  display: inline-block;
}

#cookiescript_badge {
  bottom: 0 !important;
  left: initial !important;
  right: 0 !important;
  padding: 2px !important;
}

#cookiescript_badge .cookiescriptlogo {fill: #90A8C1;}

.rp-image-section.crop .rp-preview {
  overflow: auto !important;
}

.rfm-marquee {
  min-width: initial !important;
}

.rp-preview {
  touch-action: manipulation !important;
  /* Allows for more responsive touch interactions */
}

.ReactCrop {
  touch-action: manipulation !important;
  /* Ensure smooth touch resizing */
}

.cropper-modal {
  background-color: transparent;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate-fadeIn {
  animation: fadeIn 1s ease-in-out;
}

@keyframes big-coin-vertical-flip {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(1800deg);
  }
}

.big-coin {
  width: 136px;
  height: 136px;
  background-image: url('../images/StashCoin.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  animation: big-coin-vertical-flip 2s infinite linear;
  transform-origin: center;
}

.circle {
	border-radius:999px;
	background: grey;
	transform: rotateY(45deg);
	animation: rotateAnimation 75ms linear infinite;
}

@keyframes rotateAnimation {
	from {transform: rotateY(45deg);}
	to {transform: rotateY(225deg);}
}

.emoji-blast{
  background: none;
  border: none;
  cursor: default;
  height: 2em;
  margin-left: -1em;
  margin-top: -1em;
  position: fixed;
  user-select: none;
  width: 2em;
  z-index: 1;
}
.emoji-blast-game{
  z-index: 20 !important;
}

.game-bubble-transform {
  transition: transform 500ms ease-in-out; /* Set the transition time to 500ms */
}

.game-bubble-resize {
  z-index: 0;
  transform: scale(0.01); /* Reduce the size of the div to 50% */
}

.sponsor-logo-transform{
  transition: transform 500ms ease-in-out, border-radius 500ms ease-in-out;
}

.sponsor-logo-resize {   
  transform:scale(1.57);
  border-radius: 62px;
}

.transparency-logo-resize {  
  border-radius: 50%;
  width: 203px;
  height: 203px;
}

.hide-timer{
  transform: translate(-150px, 100px); /* Move the element to the left */
  opacity: 0;                    /* Fade out the element */
  transition: transform 500ms ease-in-out, opacity 500ms ease-in-out; 
}
.hide-amount{
  transform: translate(+150px, 100px); /* Move the element to the left */
  opacity: 0;                    /* Fade out the element */
  transition: transform 500ms ease-in-out, opacity 500ms ease-in-out; 
}

/* .timer-animation{
  opacity: 0;             
  height: 0;              
  font-size: 0;   
  line-height: 0;  
  padding:0;       
  overflow: hidden;       
  transition: all 500ms ease-in-out; 
}
.timer-animation-tranformation{
  opacity: 1;
  height: 2.625rem;          
  font-size: 1.5rem;  
  line-height: 2rem;
  padding: 0.4375rem 0.875rem;
  
}
.amountEarn{
  opacity: 0;             
  height: 0;              
  font-size: 0;   
  line-height: 0;   
  padding: 0;     
  overflow: hidden;  
  transition: all 500ms ease-in-out; 
}

.amountEarn-transformation{
  opacity: 1;
  height: 2.8125rem;              
  font-size: 1.5rem;  
  line-height: 2rem;
  padding: 0.75rem 1rem;
} */



.timer-animation {
  opacity: 0; /* Invisible initially */
  height: 0;              
  font-size: 0;   
  line-height: 0;  
  transform: translateY(50px); /* Start slightly below the logo */
  transition: opacity 500ms ease-in-out, transform 500ms ease-in-out; 
}

.timer-animation-tranformation {
  opacity: 1;
  height: 2.625rem;          
  font-size: 1.5rem;  
  line-height: 2rem;
  transform: translateY(50px); /* Move to 20px above the logo */
}

.amountEarn {
  opacity: 0; /* Invisible initially */
  height: 0; 
  font-size: 0;   
  line-height: 0;       
  transform: translateY(-50px); /* Start slightly above the logo */
  transition: opacity 500ms ease-in-out, transform 500ms ease-in-out; 
}

.amountEarn-transformation {
  opacity: 1;
  height: 2.8125rem;  
}

.amountEarn.initial-position {
  transform: translateY(-250px); 
  opacity: 0; /* Start invisible */
  transition: transform 0.5s ease-out, opacity 0.5s ease-out; /* Animation duration and easing */
}

/* Final position: normal position */
.amountEarn.final-position {
  transform: translateY(0); /* Reset to the original position */
  opacity: 1; /* Fully visible */
}

.timer.initial-position {
  transform: translateY(250px); 
  opacity: 0; /* Start invisible */
  transition: transform 0.5s ease-out, opacity 0.5s ease-out; /* Animation duration and easing */
}

.timer.final-position {
  transform: translateY(0); /* Reset to the original position */
  opacity: 1; /* Fully visible */
}

@keyframes progress {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
 
.animate-progress {
  animation: progress 3s ease-in-out forwards;
}

.date-picker-input input::-webkit-calendar-picker-indicator {
  position: relative;
  left: 38px;
  width: 24px;
  height: 24px;
  opacity: 1;
  z-index: 2;
  background-image: url(../images/calendar.svg);
}

.date-picker-input .react-datepicker-wrapper {
  width: 100%;
}

.date-picker-input .react-datepicker-wrapper input {
  border: 1px solid rgb(144 168 193 / 0.7) !important;
  border-radius: 0.375rem !important;
  background-image: url(./../images/calendar.svg);
  background-repeat: no-repeat;
  background-position: 97% 50%;
}

.date-picker-input .react-datepicker-wrapper input.react-datepicker-ignore-onclickoutside {
  border-width: 2px !important;
}

.sponsor-page-bottom-btn button {
  font-size: 20px !important;
}